import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rdizon/Documents/medgrocer/projects/alorica-mh/src/components/Layout/Layout.js";
import Container from "layout/Container";
import Collapsible from "elements/Collapsible";
import SEO from "layout/SEO";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Beta Disclaimer" mdxType="SEO" />
      <h2>{`Beta Disclaimer`}</h2>
      <hr />
      <div className="message">
        <div className="message-body">
          <p>{`Please note that this is a beta version of the MedGrocer website which is
still undergoing final testing before its official release on April 13, 2020.`}</p>
        </div>
      </div>
      <div className="content is-small">
        <p>{`The platform, its software and all content found on it are provided on an
“as is” and “as available” basis. MedGrocer does not give any warranties,
whether express or implied, as to the suitability or usability of the
website, its software or any of its content.`}</p>
        <p>{`MedGrocer will not be liable for any loss, whether such loss is direct,
indirect, special or consequential, suffered by any party as a result
of their use of the MedGrocer website, its software or content. Any
downloading or uploading of material to the website is done at the
user’s own risk and the user will be solely responsible for any
damage to any computer system or loss of data that results from such
activities.`}</p>
        <p>{`Should you encounter any bugs, glitches, lack of functionality or
other problems on the website, please let us know immediately so we
can rectify these accordingly. Your help in this regard is greatly
appreciated! You can write to us by answering this `}<a href="https://bit.ly/MGBetaReport" target="_blank" className="has-text-weight-bold">{`form`}</a>{`.`}</p>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      